<template>
  <div class="shop-goods-list-page">
    <en-table-layout :tableData="pageData.data" :search="true" @search="search" :loading="loading"
      @selection-change="handleSelectionChange" @sort-change="handleSortChange" ref="tableWrap">

      <template slot="toolbar" class="row raw no-gutters align-items-center w-100">
        <el-form-item style="width:344px" label-width="70px" label="分销总价">
          <el-input-number size="small" v-model.trim="params.lowEnterPrice" placeholder="￥分销总价最小" style="width:125px;" />
          <span style="margin: 0 6px;letter-spacing: -2px;color:#999999;">---</span>
          <el-input-number size="small" v-model.trim="params.highEnterPrice" placeholder="￥分销总价最大" style="width:125px;" />
        </el-form-item>
        <el-form-item style="width:344px" label-width="70px" label="市场总价">
          <el-input-number size="small" v-model.trim="params.lowMktPrice" placeholder="￥市场总价最小" style="width:125px;" />
          <span style="margin: 0 6px;letter-spacing: -2px;color:#999999;">---</span>
          <el-input-number size="small" v-model.trim="params.highMktPrice" placeholder="￥市场总价最大" style="width:125px;" />
        </el-form-item>
        <el-form-item label="礼包分类" label-width="100px">
          <el-cascader clearable style="width: 215px;" v-model="categoryParams" :options="JDCategory.children"
            :props="JDCategory.props" placeholder="请选择礼包分类" class="cas-select" />
        </el-form-item>

      </template>
      <template slot="toolbar_btn">
        <el-form-item label="区域偏好" style="margin-bottom: 0;">
          <el-input placeholder="请输入关键词进行搜索" v-model.trim="params.labelNamesArea" clearable></el-input>
        </el-form-item>
        <el-form-item label="套餐类型" style="margin-bottom: 0;">
          <el-select v-model="params.comboType" style="width: 100px" clearable>
            <el-option value label="全部"></el-option>
            <el-option :value="1" label="京东"></el-option>
            <el-option :value="2" label="线下品"></el-option>
            <el-option :value="3" label="京东+线下品"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否异常" style="margin-bottom: 0;">
          <el-select v-model="params.normalStatus" style="width: 100px">
            <el-option value label="全部"></el-option>
            <el-option :value="0" label="异常"></el-option>
            <el-option :value="1" label="正常"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="col-auto" style="margin-bottom: 0;">
          <el-input style="width: 300px;" size="medium" placeholder="请输入关键词" v-model.trim="params.keyWord" clearable>
            <el-select v-model="key_word" slot="prepend" placeholder="请选择" style="width: 140px;">
              <el-option label="礼包名称" value="gift_name"></el-option>
              <el-option label="套餐编号" value="id"></el-option>
              <el-option label="前端礼包名称" value="web_gift_name"></el-option>
              <el-option label="商品名称" value="goods_name"></el-option>
              <el-option label="备注信息" value="remark"></el-option>
              <el-option label="归属卡券" value="card_name"></el-option>
              <el-option label="标签名称" value="labelNamesGift"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item class="col-auto">

          <el-button @click="search" type="primary" size="small">搜索</el-button>
        </el-form-item>
        <el-form-item class="col-auto ml-2" style="margin-top:8px">

          <el-button @click="handleAddCombo" type="primary" size="small">新建礼包</el-button>
          <el-button size="small" @click="handlePick2Card" type="primary">挑选至卡券</el-button>
          <el-dropdown @command="exportbatchOps" size="small" style="margin-left: 10px;">
            <el-button type="primary" size="small">
              导出套餐方案 <i class="el-icon-arrow-down el-icon--right ml-2"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="exportPPT">导出PPT方案1</el-dropdown-item>
              <el-dropdown-item command="twoExportPPT">导出PPT方案2</el-dropdown-item>
              <el-dropdown-item command="exportExcel">导出Excel方案</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
      </template>
      <el-form slot="search">
        <div class="row no-gutters align-items-center" style="margin-top:8px;">
          <el-row>
            <el-col :span="7">
              <el-form-item label="销售总价" label-width="100px">
                <el-input-number style="width: 100px;" size="small" v-model="params.low_price" placeholder="销售总价最小" />
                <span style="margin: 0 5px;">-</span>
                <el-input-number style="width: 100px;" size="small" v-model="params.high_price" placeholder="销售总价最大" />
              </el-form-item>
            </el-col>
            <el-col :span="7" v-if="parentLogin === 1">
              <el-form-item label="分销利润率" label-width="100px">
                <el-input-number style="width: 100px;" size="small" v-model="params.lowDistributionProfit"
                  placeholder="最低利润率" />
                <span style="margin: 0 5px;">-</span>
                <el-input-number style="width: 100px;" size="small" v-model="params.highDistributionProfit"
                  placeholder="最高利润率" />
              </el-form-item>
            </el-col>
            <el-col v-if="parentLogin === 1" :span="7">
              <el-form-item label="销售价利润率" label-width="100px">
                <el-input-number style="width: 100px;" size="small" v-model="params.lowPriceProfit" placeholder="最低利润率" />
                <span style="margin: 0 5px;">-</span>
                <el-input-number style="width: 100px;" size="small" v-model="params.highPriceProfit"
                  placeholder="最高利润率" />
              </el-form-item>
            </el-col>
            <el-col v-if="parentLogin === 1" :span="7">
              <el-form-item label="市场利润率" label-width="100px">
                <el-input-number style="width: 100px;" size="small" v-model="params.lowMarketProfit"
                  placeholder="最低利润率" />
                <span style="margin: 0 5px;">-</span>
                <el-input-number style="width: 100px;" size="small" v-model="params.highMarketProfit"
                  placeholder="最高利润率" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="创建时间" class="col-auto" size="small">
                <el-date-picker style="width: 220px" v-model="createTimeFilter" type="daterange" align="center"
                  size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
                  end-placeholder="结束日期" value-format="timestamp" :default-time="['00:00:00', '23:59:59']"
                  @change="createTimeFilterChange"></el-date-picker>
              </el-form-item>

            </el-col>
            <el-col :span="7">
              <el-form-item class="col-auto" label="所属公司">
                <el-input style="width: 200px;" placeholder="请输入所属公司" v-model.trim="params.company" clearable
                  size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="状态" class="col-auto" size="small">
                <el-select v-model="params.comboStatus" placeholder="请选择" clearable style="width:110px;" size="small">
                  <el-option label="全部" value></el-option>
                  <el-option label="禁用" :value="0"></el-option>
                  <el-option label="启用" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item v-if="force_exchange_goods_open" label="是否开启智能换货" class="col-auto" size="small">
                <el-select v-model="params.goods_exchange" placeholder="请选择" clearable style="width:110px;" size="small">
                  <el-option label="全部" value></el-option>
                  <el-option label="已参与" :value="1"></el-option>
                  <el-option label="未参与" :value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <template slot="table-columns">
        <el-table-column type="selection" :key="'selection'" />

        <el-table-column prop="id" label="编号" width="80" fixed="left">
          <template slot-scope="{row}">{{ row.id }}</template>
        </el-table-column>

        <el-table-column label="礼包图片" width="80" fixed="left" class-name="goods-cover-wrapper" :key="'thumbnail'">
          <template slot-scope="{row}">
            <el-popover placement="right" trigger="hover">
              <img :src="row.thumbnail" alt style="width: 300px" />
              <!-- <img :src="row.thumbnail" class="goods-cover" alt="" slot="reference" /> -->
              <div slot="reference">
                <img :src="row.thumbnail" class="goods-cover" alt />
                <img class="goods-tag" :src="getRowTagImage(row)" alt />
              </div>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="礼包名称" width="180" :show-overflow-tooltip="true" fixed="left" :key="'gift_name'">
          <template slot-scope="scope">{{ scope.row.gift_name }}</template>
        </el-table-column>

        <el-table-column label="前端礼包名称" width="180" :show-overflow-tooltip="true" :key="'web_gift_name'">
          <template slot-scope="scope">
            <div v-if="!scope.row.showWebNameInput" @click="_ => $set(scope.row, 'showWebNameInput', true)"
              style="width:100%;min-height:20px">
              {{ scope.row.web_gift_name }}</div>
            <el-input v-else v-model.trim="scope.row.web_gift_name" :maxlength="32" v-focus
              @blur="changeWebName(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="礼包分类" width="180" key="category_name" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.category_name }}
          </template>
        </el-table-column>
        <el-table-column label="商品种类" :key="'goods_kind_num'">
          <template slot-scope="scope">
            <span class="num" @click="handleComboGoodsNum(scope.row)">
              {{
                scope.row.goods_kind_num
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="分销总价" width="110" :key="'enterprisePrice'" prop="enterprisePrice" sortable="custom">
          <template slot-scope="scope">{{ scope.row.enterprise_sum_price | unitPrice('￥') }}</template>
        </el-table-column>
        <el-table-column v-if="parentLogin === 1" label="分销利润率" width="130" prop="distributionProfit" sortable="custom"
          :key="'distribution_profit'">
          <template slot="header">
            <el-tooltip effect="dark" placement="top">
              <label class="cursor-pointer"><i class="el-icon-warning-outline"></i>分销利润率</label>
              <div slot="content">
                分销利润率 = (分销总价-会员总价) /分销总价*100%
              </div>
            </el-tooltip>
          </template>
          <template slot-scope="scope">{{ scope.row.distribution_profit ? scope.row.distribution_profit.toFixed(2) : 0
          }}%</template>
        </el-table-column>
        <el-table-column label="销售总价" width="110" :key="'shopPrice'" prop="shopPrice" sortable="custom">
          <template slot-scope="scope">
            <span>{{ scope.row.shop_sum_price | unitPrice('￥') }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="parentLogin === 1" label="销售利润率" width="130" prop="priceProfit" sortable="custom"
          :key="'price_profit'">
          <template slot="header">
            <el-tooltip effect="dark" placement="top">
              <label class="cursor-pointer"><i class="el-icon-warning-outline"></i>销售利润率</label>
              <div slot="content">
                销售利润率 = (销售总价-会员总价) /销售总价*100%
              </div>
            </el-tooltip>
          </template>
          <template slot-scope="scope">{{ scope.row.price_profit ? scope.row.price_profit.toFixed(2) : 0 }}%</template>
        </el-table-column>
        <el-table-column v-if="parentLogin === 1" label="会员总价" width="110" prop="memberPrice" sortable="custom"
          :key="'sum_price'">
          <template slot-scope="scope">{{ scope.row.sum_price | unitPrice('￥') }}</template>
        </el-table-column>
        <el-table-column label="市场总价" width="110" prop="marketPrice" sortable="custom" :key="'market_sum_price'">
          <template slot-scope="scope">{{ scope.row.market_sum_price | unitPrice('￥') }}</template>
        </el-table-column>
        <el-table-column v-if="parentLogin === 1" label="市场利润率" width="130" prop="marketProfit" sortable="custom"
          :key="'market_profit'">
          <template slot="header">
            <el-tooltip effect="dark" placement="top">
              <label class="cursor-pointer"><i class="el-icon-warning-outline"></i>市场利润率</label>
              <div slot="content">
                市场利润率 = (市场总价-会员总价) /市场总价*100%
              </div>
            </el-tooltip>
          </template>
          <template slot-scope="scope">{{ scope.row.market_profit ? scope.row.market_profit.toFixed(2) : 0 }}%</template>
        </el-table-column>
        <!-- <el-table-column label="套餐价" width="110" :key="'comboPrice'" prop="comboPrice" sortable="custom">
          <template slot-scope="scope">
            <span>{{ scope.row.combo_price | unitPrice('￥') }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="parentLogin === 1" label="利润率" width="100" prop="profitMargin" sortable="custom"
          :key="'shop_profit'">
          <template slot-scope="scope">{{ scope.row.shop_profit.toFixed(2) || 0 }}%</template>
        </el-table-column> -->
        <el-table-column label="套餐类型" width="160" prop="combo_type" :key="'combo_type'">
          <template slot-scope="scope">
            <span v-if="scope.row.combo_type == 1">京东</span>
            <span v-else-if="scope.row.combo_type == 2">线下品</span>
            <span v-else-if="scope.row.combo_type == 3">京东+线下品</span>
          </template>
        </el-table-column>
        <el-table-column label="区域偏好" width="160" show-overflow-tooltip prop="label_names_area"
          :key="'label_names_area'" />
        <el-table-column label="标签" width="160" show-overflow-tooltip prop="label_names_gift" :key="'label_names_gift'" />
        <el-table-column label="创建时间" width="160" prop="createTime" :key="'create_time'" sortable="custom">
          <template slot-scope="scope">{{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm') }}</template>
        </el-table-column>

        <el-table-column label="更新时间" width="160" prop="updateTime" :key="'update_time'" sortable="custom">
          <template slot-scope="scope">{{ scope.row.update_time | unixToDate('yyyy-MM-dd hh:mm') }}</template>
        </el-table-column>

        <el-table-column label="礼包状态" width="80" :key="'status'">
          <template slot-scope="scope">
            <span>{{ scope.row.status | comboStatus }}</span>
          </template>
        </el-table-column>

        <el-table-column label="所属公司" width="100" :key="'company'" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.company }}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="force_exchange_goods_open" label="是否开启智能换货" width="80" :key="'goods_exchange'">
          <template slot-scope="scope">
            <el-switch @change="goodsExchange($event, scope.row)" v-model="scope.row.goods_exchange"
              active-color="#13ce66" inactive-color="#ff4949"></el-switch>
          </template>
        </el-table-column>

        <el-table-column label="备注信息" :show-overflow-tooltip="true" width="200" :key="'remark'">
          <template slot-scope="scope">
            <span class="num"
              @click="() => { remarkDialog.display(); remarkForm = { id: scope.row.id }; remark = scope.row.remark }">{{
                scope.row.remark || "请填写备注信息" }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" :render-header="renderHeader" :key="Math.random()" fixed="right">
          <template slot-scope="scope">
            <div class="operate" style="white-space: nowrap; display: inline-block">
              <el-tooltip v-if="!scope.row.can_delete" class="item" effect="dark" content="绑卡记录" placement="top-start">
                <el-button size="mini" type="text" @click="handleComboBinding(scope.row)"><img :src="changelog_icon"
                    alt=""></el-button>
              </el-tooltip>

              <el-tooltip v-if="scope.row.can_delete" class="item" effect="dark" content="删除" placement="top-start">
                <!-- 礼包未绑卡的时候，可以删除（状态默认为启用状态）；一旦绑卡后，删除按钮去掉。 -->
                <el-button size="mini" type="text" @click="handleDeleteCombo(scope.row)"><img :src="delete_icon"
                    alt=""></el-button>
              </el-tooltip>

              <el-tooltip class="item" effect="dark" content="编辑" placement="top-start">
                <el-button size="mini" type="text" @click="handleEditCombo(scope.row)"><img :src="edit_icon"
                    alt=""></el-button>
              </el-tooltip>

              <el-tooltip v-if="scope.row.status === 1" class="item" effect="dark" content="禁用" placement="top-start">
                <el-button size="mini" type="text" @click="handleNoUseCombo(scope.row)"><img :src="disabled_icon"
                    alt=""></el-button>
              </el-tooltip>

              <el-tooltip v-if="scope.row.status === 0" class="item" effect="dark" content="启用" placement="top-start">
                <el-button size="mini" type="text" @click="handleUseCombo(scope.row)"><img :src="enable_icon"
                    alt=""></el-button>
              </el-tooltip>
            </div>

            <!-- <el-button
              size="mini"
              type="primary"
              @click="()=>{remarkDialog.display();remarkForm = {id :scope.row.id}}"
            >备注</el-button>-->
          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no" :page-size="pageData.page_size" :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout" background :total="pageData.data_total" />
    </en-table-layout>

    <!-- 挑选至卡券 -->
    <el-dialog-x-dialog title="操作" :visible.sync="pick2CardFormVisible" width="30%">
      <el-form :model="pick2CardForm" style="padding: 10px 20px;">
        <el-form-item label="请选择">
          <el-select v-model="pick2CardForm.cardId" placeholder="套餐卡券选择" clearable filterable>
            <el-option v-for="card in cards" :key="card.id" :label="card.card_name" :value="card.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="text-align:right">
          <el-button @click="pick2CardForm.cardId = ''; pick2CardFormVisible = false" size="small">取 消</el-button>
          <el-button type="primary" size="small" @click="handlePick2CardConfirm">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog-x-dialog>

    <!--绑卡记录-->
    <el-dialog-x-dialog title="绑卡记录" :visible.sync="comboBindingRecordsVisible">
      <en-table-layout :table-data="comboBindingTableData.data" :loading="comboRecordsLoading">
        <template slot="toolbar">
          <el-form-item>
            <el-input v-model.trim="comboBindingRecordsParams.card_name" placeholder="请输入卡券名称" clearable />
          </el-form-item>
          <el-form-item>
            <el-button @click="searchRecords" size="small" type="primary">搜索</el-button>
          </el-form-item>
        </template>
        <template slot="table-columns">
          <el-table-column :key="'code'" label="编号" width="80" fixed="left">
            <template slot-scope="scope">{{ code(scope.$index, comboBindingRecordsParams) }}</template>
          </el-table-column>
          <el-table-column label="所绑卡券名称" width="200" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{ scope.row.card_name }}</template>
          </el-table-column>
          <el-table-column label="绑卡时间">
            <template slot-scope="scope">{{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm') }}</template>
          </el-table-column>
        </template>
        <el-pagination slot="pagination" @size-change="handlePageSizeChangeRecord"
          @current-change="handlePageCurrentChangeRecord" :current-page="comboBindingTableData.page_no"
          :page-size="comboBindingTableData.page_size" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
          :total="comboBindingTableData.data_total" />
      </en-table-layout>
    </el-dialog-x-dialog>

    <!--查看商品数量，商品展示-->
    <el-dialog-x-dialog title="商品展示" :visible.sync="comboGoodsNumVisible">
      <en-table-layout :table-data="comboGoodsNumTableData.data">
        <template slot="table-columns">
          <el-table-column label="商品图片" width="60" fixed="left" class-name="goods-cover-wrapper" :key="'thumbnail'">
            <template slot-scope="{row}">
              <el-popover placement="right" trigger="hover">
                <img :src="row.thumbnail" alt style="width: 300px" />
                <img :src="row.thumbnail" class="goods-cover" alt slot="reference" />
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="前端展示图片" width="60" fixed="left" class-name="goods-cover-wrapper" :key="'web_thumbnail'">
            <template slot-scope="{row}">
              <el-popover placement="right" trigger="hover">
                <img :src="row.web_thumbnail ? row.web_thumbnail : row.thumbnail" alt style="width: 300px" />
                <img :src="row.web_thumbnail ? row.web_thumbnail : row.thumbnail" class="goods-cover" alt
                  slot="reference" />
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="商品名称" :show-overflow-tooltip="true" fixed="left">
            <template slot-scope="scope">{{ scope.row.goods_name }}</template>
          </el-table-column>
          <el-table-column label="前端名称" :show-overflow-tooltip="true">
            <template slot-scope="scope">{{ scope.row.goods_alias }}</template>
          </el-table-column>
          <el-table-column label="商品来源" width="100">
            <!-- 商品来源，0普通商品，1京东商品，2苏宁商品 -->
            <template slot-scope="{row}">
              <template v-if="row.goods_source === 0 && row.self_operated === 0">平台商品</template>
              <template v-if="row.goods_source === 0 && row.self_operated === 1">自有商品</template>
              <template v-if="row.goods_source === 1 && row.self_operated === 0">京东商品</template>
              <!-- <template v-if="row.goods_source === 0">{{ row.self_operated === 1 ? '自有商品' : '平台商品' }}</template> -->
              <!-- <template v-if="row.goods_source === 2">苏宁商品</template> -->
            </template>
          </el-table-column>
          <el-table-column v-if="MixinIsFormEnterprise" label="所属供应商">
            <template slot-scope="scope">{{ scope.row.supplier_name }}</template>
          </el-table-column>
          <el-table-column label="数量" width="90">
            <template slot-scope="scope">{{ scope.row.num }}</template>
          </el-table-column>
        </template>
      </en-table-layout>
    </el-dialog-x-dialog>

    <x-dialog :proxy="remarkDialog">
      <div style="padding:10px">
        <el-input type="textarea" :autosize="{ minRows: 8, maxRows: 10 }" placeholder="请输入100字以内的备注信息" maxlength="100"
          v-model="remark"></el-input>
      </div>
    </x-dialog>

    <el-dialog-x-dialog width="750px" title="导出PPT方案" :visible.sync="exportPPTVisible">
      <el-form class="exportPPTForm" ref="exportPPTForm" :model="exportPPTForm" :rules="exportPPTFormRule"
        label-width="130px">
        <el-form-item style="margin-bottom:10px" label="套餐方案名称" prop="package_name">
          <el-input style="width:250px" v-model.trim="exportPPTForm.package_name" :maxlength="20" :minlength="1"
            placeholder="请输入套餐方案名称"></el-input>
        </el-form-item>
        <el-form-item style="margin:0" label="套餐礼包价格展示">
          <el-radio-group v-model="exportPPTForm.package_gift_price">
            <el-radio :label="0">市场总价</el-radio>
            <el-radio :label="1">销售总价</el-radio>
            <el-radio :label="2">分销总价</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="margin:0" label="套餐单品市场价">
          <el-radio-group v-model="exportPPTForm.package_goods_price">
            <el-radio :label="1">显示</el-radio>
            <el-radio :label="0">不显示</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="margin:0" label="PPT页面图片显示">
          <el-radio-group v-model="exportPPTForm.package_goods_img">
            <el-radio :label="0">单品图片</el-radio>
            <el-radio :label="1">套餐礼包图片</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="margin:0" label="导出套餐分类页面">
          <el-radio-group @input="giftCategoryChange" v-model="exportPPTForm.gift_category">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="margin:0" label="PPT背景图设置">
        </el-form-item>
        <div style="display:flex;">
          <el-form-item label="首页封面图">
            <el-upload class="site-logo" accept=".jpeg, .png, .jpg" :action="MixinUploadApi" :show-file-list="false"
              :on-success="(res) => { exportPPTForm.gift_home_img = res.url }" :multiple="false">
              <div v-if="exportPPTForm.gift_home_img">
                <img :src="exportPPTForm.gift_home_img" class="site-logo-img" />
                <i @click="exportPPTForm.gift_home_img = ''" class="el-icon-circle-close deleteImg"></i>
              </div>
              <i v-else class="el-icon-plus logo-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item v-if="exportPPTForm.gift_category == 1" label-width="90px" label="套餐分类图">
            <el-upload class="site-logo" accept=".jpeg, .png, .jpg" :action="MixinUploadApi" :show-file-list="false"
              :on-success="(res) => { exportPPTForm.gift_category_img = res.url }" :multiple="false">
              <div v-if="exportPPTForm.gift_category_img">
                <img :src="exportPPTForm.gift_category_img" class="site-logo-img" />
                <i @click="exportPPTForm.gift_category_img = ''" class="el-icon-circle-close deleteImg"></i>
              </div>
              <i v-else class="el-icon-plus logo-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label-width="120px" label="普通页面背景图">
            <el-upload class="site-logo" accept=".jpeg, .png, .jpg" :action="MixinUploadApi" :show-file-list="false"
              :on-success="(res) => { exportPPTForm.gift_background_img = res.url }" :multiple="false">
              <div v-if="exportPPTForm.gift_background_img">
                <img :src="exportPPTForm.gift_background_img" class="site-logo-img" />
                <i @click="exportPPTForm.gift_background_img = ''" class="el-icon-circle-close deleteImg"></i>
              </div>
              <i v-else class="el-icon-plus logo-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </div>
        <p style="padding-left:140px">提示：请上传1张大小不超10MB，格式为jpg、png、jpeg的图片。建议尺寸：1200*764。</p>
        <el-form-item style="text-align:right">
          <el-button @click="exportPPTVisible = false">取 消</el-button>
          <el-button type="primary" @click="exportPPTConfirm">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog-x-dialog>
  </div>
</template>

<script>
import * as API_goods from '@/api/goods'
import * as API_Combo from '@/api/combo-card-shop/combo'
import * as API_Order from '@/api/order'
import EnTableLayout from '@/../ui-components/TableLayout/src/main'
import XDialog from '@/components/x-dialog/x-dialog'
import { $xDialog } from '@/components/x-dialog/dialog.proxy'
import { mergesImgDownload } from "@/utils";
import statusErr from "@/assets/goods/statusErr.png";
import changelog_icon from "@/assets/button_icon/changelog.png";//绑卡记录
import edit_icon from "@/assets/button_icon/edit.png";//编辑
import enable_icon from "@/assets/button_icon/enable.png";//启用
import disabled_icon from "@/assets/button_icon/disabled.png";//禁用
import delete_icon from "@/assets/button_icon/delete.png";//删除
import { $giftsCategory } from '@/pages/goods/services/gifts-category'
export default {
  name: 'comboListComboCard',
  components: {
    EnTableLayout,
    XDialog
  },
  data () {
    return {
      categoryParams: '',
      JDCategory: $giftsCategory.create(),
      exportPPTVisible: false,
      exportPPTForm: {
        package_name: '', // 套餐方案名称
        package_gift_price: 0, // 0市场总价 1销售总价 2分销总价
        package_goods_price: 1, // 套餐单品市场价展示：0:不显示 1:显示
        package_goods_img: 0, // PPT页面图片展示：0:单品图片 1:套餐礼包图片
        gift_category: 0, // 导出套餐分类页面：0:否 1:是
        gift_home_img: '', // 首页封面图
        gift_category_img: '', // 套餐分类图
        gift_background_img: '' // 普通页面背景图
      },
      exportPPTFormRule: {
        package_name: [
          { required: true, message: '请填写套餐方案名称', trigger: 'blur' }
        ]
      },
      changelog_icon,
      edit_icon,
      enable_icon,
      disabled_icon,
      delete_icon,
      force_exchange_goods_open: false,
      key_word: 'gift_name',
      remarkForm: {},
      remark: '',
      remarkDialog: $xDialog.create({
        title: '备注信息',
        width: '40vw',
        showClose: true,
        displayFooterExtras: true,
        beforeConfirm: this.editRemark
      }),
      /** 列表loading状态 */
      loading: false,
      /** 绑卡记录列表loading状态 */
      comboRecordsLoading: false,
      idsList: [],
      multipleSelection: [],
      showDialog: false,

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        highEnterPrice: undefined,
        lowEnterPrice: undefined,
        shop_profit_min: undefined,
        highMktPrice: undefined,
        lowMktPrice: undefined,
        keyWord: '',
        company: '',
        sort: 'def_desc',
        comboStatus: '',
        comboType: '',
        normalStatus: void 0
      },

      /** 列表分页数据 */
      pageData: {},

      /** 绑卡记录字段*/
      comboBindingRecordsVisible: true,
      comboBindingTableData: {},
      comboBindingRecordsParams: {
        page_no: 1,
        page_size: 20,
        card_name: ''
      },
      /** 查看商品数量，商品展示字段*/
      comboGoodsNumVisible: true,
      comboGoodsNumTableData: {},
      /**挑选卡券字段 */
      pick2CardFormVisible: false,
      pick2CardForm: {
        cardId: ''
      },
      cards: [],
      /**是否是企业跳转过来 */
      parentLogin: 0,
      createTimeFilter: [],
      isGlzcShop: false,
      exportType: 5,
    }
  },
  filters: {
    /** 礼包状态格式化 */
    comboStatus (status) {
      switch (status) {
        case 0:
          return '禁用'
        case 1:
          return '启用'
      }
    }
  },
  async created () {
    let shopidList = await API_goods.getExportShopIds()
    shopidList = shopidList.data.split(',')
    this.isGlzcShop = shopidList.includes((this.$store.state.user.shop.parent_id).toString())

    API_Order.getShopExtAuth().then(res => {
      this.force_exchange_goods_open = res.force_exchange_goods_open === 'OPEN'
      console.log('礼包智能换货开关', this.force_exchange_goods_open)
    })
    API_goods.getLoginType().then(res => {
      this.parentLogin = res.parentLogin
      this.isPermission()
    })
    if (this.MixinIsFormEnterprise) {//是企业跳转过来
      if (this.$store.getters.isSupplierName && this.isGlzcShop) {//有供应商并且是国联智诚
        this.exportType = 1
      } else {
        if (this.$store.getters.isSupplierName && !this.isGlzcShop) {
          this.exportType = 2
        } else {
          if (!this.$store.getters.isSupplierName && this.isGlzcShop) {
            this.exportType = 3
          } else {//都没有
            this.exportType = 4
          }
        }
      }
    } else {
      this.exportType = 5
    }
  },
  activated () {
    this.GET_ComboList()
    this.$store.commit('DEL_VISITED_VIEWS', this.$route)
  },
  mounted () {
    if (this.$route.params.card_name) {
      this.key_word = 'card_name';
      this.params.keyWord = this.$route.params.card_name
    }
    this.GET_ComboList()
    this.$store.commit('DEL_VISITED_VIEWS', this.$route)
  },
  methods: {
    // 表头部重新渲染
    renderHeader (h, { column, $index }) {
      setTimeout(() => {
        // 获取操作按钮组的元素
        const opts = document.getElementsByClassName('operate');
        let widthArr = [];
        // 取操作组的最大宽度
        Array.prototype.forEach.call(opts, function (item) {
          if (item.innerText || item.innerHTML) {
            widthArr.push(item.offsetWidth);
          }
        });
        // 重新设置列标题及宽度属性
        if (widthArr.length > 0) {
          column.width = Math.max(...widthArr) + 24;
        } else {
          column.width = 80;
        }
      }, 1)

      return h('span', '操作');
    },
    getRowTagImage (row) {
      if (row.normal_status === 0) return statusErr
    },
    exportbatchOps (ops) {//导出PPT
      switch (ops) {
        case 'exportPPT':
          return this.importMore();
        case 'twoExportPPT':
          return this.importMoreTwo();
        case 'exportExcel':
          return this.exportMore();
      }
    },
    //站点订单列表导出PPT方法
    importMore () {
      if (this.multipleSelection.length == 0) {
        this.$message.error("请勾选礼包");
        return;
      }
      API_goods.getImportRecordList({ combo_ids: this.idsList, page_no: 1, page_size: 9999 }).then(res => {
        let a = document.createElement('a');//创建a标签
        a.href = res;//文件url
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();//触发下载
        document.body.removeChild(a);
        this.$message.success('导出成功')
      })
    },
    giftCategoryChange (val) {
      if (val == 0) {
        this.exportPPTForm.gift_category_img = ''
      }
    },
    //导出PPT方案2
    importMoreTwo () {
      if (this.multipleSelection.length == 0) {
        this.$message.error("请勾选礼包");
        return;
      }
      this.exportPPTVisible = true
    },
    resetPPTForm () {//重置表单
      this.exportPPTForm = {
        package_name: '', // 套餐方案名称
        package_gift_price: 0, // 0市场总价 1销售总价 2分销总价
        package_goods_price: 1, // 套餐单品市场价展示：0:不显示 1:显示
        package_goods_img: 0, // PPT页面图片展示：0:单品图片 1:套餐礼包图片
        gift_category: 0, // 导出套餐分类页面：0:否 1:是
        gift_home_img: '', // 首页封面图
        gift_category_img: '', // 套餐分类图
        gift_background_img: '' // 普通页面背景图
      }
    },
    exportPPTConfirm () {
      this.$refs['exportPPTForm'].validate(valid => {
        if (valid) {
          const dataform = Object.fromEntries(Object.entries(this.exportPPTForm).filter(([key, value]) => value || value === 0))
          API_goods.newExportComboList({ ...dataform, combo_ids: this.idsList }).then(res => {
            let a = document.createElement('a');//创建a标签
            a.href = res;//文件url
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();//触发下载
            document.body.removeChild(a);
            this.$message.success('导出成功')
            this.exportPPTVisible = false
            this.resetPPTForm()
          })
        }
      })
    },
    //站点订单列表导出Excel方法
    exportMore () {
      if (this.idsList.length == 0) {
        this.$message.error("请勾选礼包");
        return;
      }
      let exportExcel = {
        type: this.exportType,//类型
        gift_list: []//礼包集合
      }
      this.multipleSelection.map(item => {
        let obj = {//格式化单个礼包数值
          goods_list: [],//商品集合
          sub_totals: []//合计
        };
        if (this.exportType == 1) {//企业挑战点  都有供应商和大平台成本价
          let all_admin_cost = 0 //大后台（成本价）合计
          item.goods_volist.map(els => {
            //判断是否是京东商品
            let jdUrl = '';//京东链接
            let goods_void = els.shop_goods_vo.goods_do.sn;//商品编码
            let adminCost = els.shop_goods_vo.goods_do.cost
            //自有商品
            if (els.shop_goods_vo.goods_do.goods_source == 0 && els.shop_goods_vo.goods_do.self_operated === 1) {
              adminCost = ''
            }
            if (els.supplier_name == '京东供应商') {
              jdUrl = `https://item.jd.com/${els.shop_goods_vo.goods_do.ext_goods_id}.html`;//京东链接
              goods_void = els.shop_goods_vo.goods_do.ext_goods_id;//商品编码
            }
            if (adminCost) {
              all_admin_cost = this.addPrice(all_admin_cost, this.accMul(adminCost, els.num))
            }
            let arrays = [
              item.id,//编号
              item.category_name,//套餐分类
              item.label_names_gift,//标签
              els.goods_name,//商品名称
              els.num,//数量
              "",//图片占位（空）
              "",//图片占位（空）
              "",//图片占位（空）
              "",//参数（空）
              "",//产品说明（空）
              els.shop_goods_vo.goods_do.mktprice,//市场价
              els.shop_goods_vo.revise_price,//销售价
              item.shop_sum_price,//套餐价
              jdUrl,//京东链接
              '',//报价截止时间（空）
              goods_void,//商品编码
              els.shop_goods_vo.enterprise_revise_price,//分销价
              '',//分销利润率
              '',//成本（空）
              adminCost,//成本价（大后台）
              '',//运费（空）
              '',//仓储成本（空）
              '',//销售提成（空）
              '',//卡券成本（空）
              ['已下架', '已上架'][els.shop_goods_vo.shop_goods_status],//商品状态
              els.supplier_name,//供应商名称
              '',//成本最终（空）
              '',//最终毛利率（空）
              '',//采购提成（空）
              item.remark,//备注
              els.shop_goods_vo.goods_do.original,//商品图片
            ]
            obj.goods_list.push(arrays)
          })
          obj.sub_totals = [
            '',
            '',
            '',
            '合计',
            '',
            '',
            '',
            '',
            '',
            '',
            item.market_sum_price,//礼包市场总价
            item.shop_sum_price,//礼包销售总价
            item.shop_sum_price,//礼包销售总价
            '',
            '',
            '',
            item.enterprise_sum_price,//礼包分销总价
            item.distribution_profit ? item.distribution_profit.toFixed(2) : 0,//分销利润率,//礼包分销利润率
            '',
            all_admin_cost || '',//成本价（大后台）
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',//商品图片占位
          ]
        } else if (this.exportType == 2) {//企业挑战点  没有大平台成本价
          item.goods_volist.map(els => {
            //判断是否是京东商品
            let jdUrl = '';//京东链接
            let goods_void = els.shop_goods_vo.goods_do.sn;//商品编码
            if (els.supplier_name == '京东供应商') {
              jdUrl = `https://item.jd.com/${els.shop_goods_vo.goods_do.ext_goods_id}.html`;//京东链接
              goods_void = els.shop_goods_vo.goods_do.ext_goods_id;//商品编码
            }
            let arrays = [
              item.id,//编号
              item.category_name,//套餐分类
              item.label_names_gift,//标签
              els.goods_name,//商品名称
              els.num,//数量
              "",//图片占位（空）
              "",//图片占位（空）
              "",//图片占位（空）
              "",//参数（空）
              "",//产品说明（空）
              els.shop_goods_vo.goods_do.mktprice,//市场价
              els.shop_goods_vo.revise_price,//销售价
              item.shop_sum_price,//套餐价
              jdUrl,//京东链接
              '',//报价截止时间（空）
              goods_void,//商品编码
              els.shop_goods_vo.enterprise_revise_price,//分销价
              '',//分销利润率
              '',//成本（空）

              '',//运费（空）
              '',//仓储成本（空）
              '',//销售提成（空）
              '',//卡券成本（空）
              ['已下架', '已上架'][els.shop_goods_vo.shop_goods_status],//商品状态
              els.supplier_name,//供应商名称
              '',//成本最终（空）
              '',//最终毛利率（空）
              '',//采购提成（空）
              item.remark,//备注
              els.shop_goods_vo.goods_do.original,//商品图片
            ]
            obj.goods_list.push(arrays)
          })
          obj.sub_totals = [
            '',
            '',
            '',
            '合计',
            '',
            '',
            '',
            '',
            '',
            '',
            item.market_sum_price,//礼包市场总价
            item.shop_sum_price,//礼包销售总价
            item.shop_sum_price,//礼包销售总价
            '',
            '',
            '',
            item.enterprise_sum_price,//礼包分销总价
            item.distribution_profit ? item.distribution_profit.toFixed(2) : 0,//分销利润率,//礼包分销利润率
            '',

            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',//商品图片占位
          ]
        } else if (this.exportType == 3) {//企业跳站点  没有供应商名称
          let all_admin_cost = 0 //大后台（成本价）合计
          item.goods_volist.map(els => {
            //判断是否是京东商品
            let jdUrl = '';//京东链接
            let goods_void = els.shop_goods_vo.goods_do.sn;//商品编码
            let adminCost = els.shop_goods_vo.goods_do.cost
            //自有商品
            if (els.shop_goods_vo.goods_do.goods_source == 0 && els.shop_goods_vo.goods_do.self_operated === 1) {
              adminCost = ''
            }
            if (els.supplier_name == '京东供应商') {
              jdUrl = `https://item.jd.com/${els.shop_goods_vo.goods_do.ext_goods_id}.html`;//京东链接
              goods_void = els.shop_goods_vo.goods_do.ext_goods_id;//商品编码
            }
            if (adminCost) {
              all_admin_cost = this.addPrice(all_admin_cost, this.accMul(adminCost, els.num))
            }
            let arrays = [
              item.id,//编号
              item.category_name,//套餐分类
              item.label_names_gift,//标签
              els.goods_name,//商品名称
              els.num,//数量
              "",//图片占位（空）
              "",//图片占位（空）
              "",//图片占位（空）
              "",//参数（空）
              "",//产品说明（空）
              els.shop_goods_vo.goods_do.mktprice,//市场价
              els.shop_goods_vo.revise_price,//销售价
              item.shop_sum_price,//套餐价
              jdUrl,//京东链接
              '',//报价截止时间（空）
              goods_void,//商品编码
              els.shop_goods_vo.enterprise_revise_price,//分销价
              '',//分销利润率
              '',//成本（空）
              adminCost,//成本价（大后台）
              '',//运费（空）
              '',//仓储成本（空）
              '',//销售提成（空）
              '',//卡券成本（空）
              ['已下架', '已上架'][els.shop_goods_vo.shop_goods_status],//商品状态

              '',//成本最终（空）
              '',//最终毛利率（空）
              '',//采购提成（空）
              item.remark,//备注
              els.shop_goods_vo.goods_do.original,//商品图片
            ]
            obj.goods_list.push(arrays)
          })
          obj.sub_totals = [
            '',
            '',
            '',
            '合计',
            '',
            '',
            '',
            '',
            '',
            '',
            item.market_sum_price,//礼包市场总价
            item.shop_sum_price,//礼包销售总价
            item.shop_sum_price,//礼包销售总价
            '',
            '',
            '',
            item.enterprise_sum_price,//礼包分销总价
            item.distribution_profit ? item.distribution_profit.toFixed(2) : 0,//分销利润率,//礼包分销利润率
            '',
            all_admin_cost || '',//成本价（大后台）
            '',
            '',
            '',
            '',
            '',

            '',
            '',
            '',
            '',
            '',//商品图片占位
          ]
        } else if (this.exportType == 4) {//企业跳站点  都没有供应商和大平台成本价
          item.goods_volist.map(els => {
            //判断是否是京东商品
            let jdUrl = '';//京东链接
            let goods_void = els.shop_goods_vo.goods_do.sn;//商品编码
            if (els.supplier_name == '京东供应商') {
              jdUrl = `https://item.jd.com/${els.shop_goods_vo.goods_do.ext_goods_id}.html`;//京东链接
              goods_void = els.shop_goods_vo.goods_do.ext_goods_id;//商品编码
            }
            let arrays = [
              item.id,//编号
              item.category_name,//套餐分类
              item.label_names_gift,//标签
              els.goods_name,//商品名称
              els.num,//数量
              "",//图片占位（空）
              "",//图片占位（空）
              "",//图片占位（空）
              "",//参数（空）
              "",//产品说明（空）
              els.shop_goods_vo.goods_do.mktprice,//市场价
              els.shop_goods_vo.revise_price,//销售价
              item.shop_sum_price,//套餐价
              jdUrl,//京东链接
              '',//报价截止时间（空）
              goods_void,//商品编码
              els.shop_goods_vo.enterprise_revise_price,//分销价
              '',//分销利润率
              '',//成本（空）

              '',//运费（空）
              '',//仓储成本（空）
              '',//销售提成（空）
              '',//卡券成本（空）
              ['已下架', '已上架'][els.shop_goods_vo.shop_goods_status],//商品状态

              '',//成本最终（空）
              '',//最终毛利率（空）
              '',//采购提成（空）
              item.remark,//备注
              els.shop_goods_vo.goods_do.original,//商品图片
            ]
            obj.goods_list.push(arrays)
          })
          obj.sub_totals = [
            '',
            '',
            '',
            '合计',
            '',//数量
            '',//图片
            '',//图片
            '',//图片
            '',
            '',
            item.market_sum_price,//礼包市场总价
            item.shop_sum_price,//礼包销售总价
            item.shop_sum_price,//礼包销售总价
            '',
            '',
            '',
            item.enterprise_sum_price,//礼包分销总价
            item.distribution_profit ? item.distribution_profit.toFixed(2) : 0,//分销利润率,//礼包分销利润率
            '',

            '',
            '',
            '',
            '',
            '',

            '',
            '',
            '',
            '',
            '',//商品图片占位
          ]
        } else {//单独登录
          item.goods_volist.map(els => {
            //判断是否是京东商品
            let jdUrl = '';//京东链接
            let goods_void = els.shop_goods_vo.goods_do.sn;//商品编码
            if (els.supplier_name == '京东供应商') {
              jdUrl = `https://item.jd.com/${els.shop_goods_vo.goods_do.ext_goods_id}.html`;//京东链接
              goods_void = els.shop_goods_vo.goods_do.ext_goods_id;//商品编码
            }
            let arrays = [
              item.id,//编号
              item.category_name,//套餐分类
              item.label_names_gift,//标签
              els.goods_name,//商品名称
              els.num,//数量
              "",//图片占位（空）
              "",//图片占位（空）
              "",//图片占位（空）
              "",//参数
              "",//产品说明
              els.shop_goods_vo.goods_do.mktprice,//市场价
              els.shop_goods_vo.revise_price,//销售价
              item.shop_sum_price,//套餐价
              jdUrl,//京东链接
              '',//报价截止时间
              goods_void,//商品编码
              els.shop_goods_vo.enterprise_revise_price,//分销价
              ['已下架', '已上架'][els.shop_goods_vo.shop_goods_status],//商品状态
              item.remark ? item.remark : '',//备注
              els.shop_goods_vo.goods_do.original,//商品图片
            ]
            obj.goods_list.push(arrays)
          })
          obj.sub_totals = [
            '',
            '',
            '',
            '合计',
            '',
            '',
            '',
            '',
            '',
            '',
            item.market_sum_price,
            item.shop_sum_price,
            item.shop_sum_price,
            '',
            '',
            '',
            item.enterprise_sum_price,
            '',
            '',
            ''
          ]
        }
        exportExcel.gift_list.push(obj)
      })
      API_goods.exportExcelComboList(exportExcel).then(res => {
        let a = document.createElement('a');//创建a标签
        a.href = res;//文件url
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();//触发下载
        document.body.removeChild(a);
        this.$message.success('导出成功')
      })
    },
    /**
     * 两位小数加法
     */
    addPrice (a, b) {
      a = a.toString()
      b = b.toString()
      a.split('.')[1] ? a.split('.')[1].length === 1 && (a += '0') : (a += '00')
      b.split('.')[1] ? b.split('.')[1].length === 1 && (b += '0') : (b += '00')
      return (Number(a.replace('.', '')) + Number(b.replace('.', ''))) / 100
    },
    // 一个高精度乘法计算的函数
    accMul (arg1, arg2) {
      let m = 0
      let s1 = arg1.toString()
      let s2 = arg2.toString()
      try {
        m += s1.split('.')[1].length
      } catch (e) { }
      try {
        m += s2.split('.')[1].length
      } catch (e) { }
      return (
        (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
        Math.pow(10, m)
      )
    },
    /**格式化货币 */
    formatPrice (price) {
      if (typeof price !== 'number') return price
      return String(Number(price).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    createTimeFilterChange (date) {
      // debugger;
      if (Array.isArray(date)) {
        this.params.create_time_start = date[0] / 1000
        this.params.create_time_end = date[1] / 1000
      } else {
        this.params.create_time_start = ''
        this.params.create_time_end = ''
      }
    },
    isPermission () {
      API_Order.getShopExtAuth().then(res => {
        this.$store.getters.permission_routers.forEach(item => {
          if (item.path === '/combo-card-shop/shop-goods') {
            // 关闭菜单
            item.children.forEach((value, index) => {
              if (value.path === 'strong-push') {
                item.children.splice(index, 1)
              }
            })
            item.children.forEach((value, index) => {
              if (value.path === 'intelligence') {
                item.children.splice(index, 1)
              }
            })
          }
        })
        if (res.force_exchange_goods_open === 'OPEN' && this.parentLogin === 1) {
          this.$store.getters.permission_routers.forEach(item => {
            console.log(item.path)
            if (item.path === '/combo-card-shop/shop-goods') {
              // 开启菜单
              item.children.push(
                {
                  path: `strong-push`,
                  component: () =>
                    import(`@/views/combo-card-shop/goods/strongPush.vue`),
                  name: `strongPushComboCard`,
                  meta: { title: `strongPushComboCard` }
                },
                {
                  path: `intelligence`,
                  component: () =>
                    import(`@/views/combo-card-shop/goods/intelligence.vue`),
                  name: `intelligenceComboCard`,
                  meta: { title: `intelligenceComboCard` }
                }
              )
            }
          })
        }
      })
    },
    goodsExchange (val, row) {
      console.log(val, row)
      API_Combo.editAiExchangeTh(row.id, val ? 1 : 0).then(res => {
        console.log(res)
      })
    },
    editRemark () {
      if (this.remark === '' || this.remark === null) {
        return this.$message.error('备注信息不能为空')
      }
      this.remark = this.remark.replace(/\n/g, '/n')
      API_Combo.editRemarkTh(this.remarkForm.id, { remark: this.remark }).then(
        res => {
          this.remarkForm = {}
          this.$message.success('保存成功')
          this.GET_ComboList()
        }
      )
    },
    /**
     * 查看商品
     */
    handleComboGoodsNum (row) {
      console.log(row)
      this.comboGoodsNumVisible = true
      this.comboGoodsNumTableData.data = []
      row.goods_volist.forEach(item => {
        let {
          web_thumbnail,
          supplier_name,
          goods_alias,
          goods_name,
          num,
          shop_goods_vo: {
            goods_do: { goods_source, thumbnail, self_operated }
          }
        } = item
        this.comboGoodsNumTableData.data.push({
          goods_name,
          goods_alias,
          goods_source,
          self_operated,
          num,
          web_thumbnail,
          thumbnail,
          supplier_name
        })
      })
    },
    /**
     * 绑卡记录
     */
    handleComboBinding (row) {
      this.comboBindingRecordsVisible = true
      this.comboBindingRecordsParams.combo_id = row.id
      this.GET_ComboRecordsList()
    },
    /**
     * 添加套餐
     */
    handleAddCombo () {
      this.$store.dispatch('addVisitedViews', this.$route)
      this.$router.push({
        name: 'comboEditComboCard'
      })
    },
    /**
     * 挑选至卡券
     */
    handlePick2Card () {
      if (this.idsList.length) {
        API_Combo.getCanPickedCardList({ ids: this.idsList }).then(res => {
          this.cards = res
          this.pick2CardFormVisible = true
        })
      } else {
        this.$message.error('请选择挑选到卡券的礼包')
      }
    },
    /**
     * 挑选至卡券确认按钮
     */
    handlePick2CardConfirm () {
      if (this.pick2CardForm.cardId) {
        API_Combo.combo2Card(this.pick2CardForm.cardId, this.idsList).then(
          res => {
            if (res === '卡券不存在或未启用') {
              this.$message.error(res)
            } else {
              this.$message.success('挑选成功')
              this.pick2CardFormVisible = false
              this.pick2CardForm.cardId = ''
              this.GET_ComboList()
            }
          }
        )
      } else {
        this.$message.error('请选择挑选到的卡券')
      }
    },

    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size
      this.GET_ComboList()
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page
      this.GET_ComboList()
    },

    /** 绑卡记录分页大小发生改变 */
    handlePageSizeChangeRecord (size) {
      this.params.page_size = size
      this.GET_ComboRecordsList()
    },

    /** 绑卡记录分页页数发生改变 */
    handlePageCurrentChangeRecord (page) {
      this.params.page_no = page
      this.GET_ComboRecordsList()
    },

    search () {
      if (this.key_word == 'id' && Object.is(Number(this.params.keyWord), NaN)) {
        return this.$message.error('请输入正确的套餐编号')
      }
      this.params.categoryPath = this.categoryParams
      this.params.page_no = 1
      this.GET_ComboList()
    },
    /**查询绑卡记录 */
    searchRecords () {
      this.comboBindingRecordsParams.page_no = 1
      this.GET_ComboRecordsList()
    },

    /** 禁用*/
    handleNoUseCombo (row) {
      this.$confirm(
        '确定要禁用这个套餐礼包吗？禁用后前台用户将无法进行兑换。',
        '提示',
        {
          type: 'warning'
        }
      ).then(() => {
        API_Combo.changeStatus(row.id, 0).then(() => {
          this.$message.success('禁用成功')
          this.GET_ComboList()
        })
      })
    },

    /** 启用*/
    handleUseCombo (row) {
      this.$confirm('确定要启用该套餐礼包吗？', '提示', {
        type: 'warning'
      }).then(() => {
        API_Combo.changeStatus(row.id, 1).then(res => {
          if (res !== 'success') {
            this.$message.error(res)
          } else {
            this.$message.success('启用成功')
            this.GET_ComboList()
          }
        })
      })
    },

    GET_ComboList () {
      this.loading = true
      let params = {
        ...this.params
      }
      params[this.key_word] = params.keyWord
      delete params.keyWord
      // this.params.shopId = this.$store.getters.shopInfo.shop_id;
      API_Combo.getComboList(params).then(response => {
        if (response.data.length === 0 && response.page_no > 1) {
          this.params.page_no--
          return this.GET_ComboList()
        }

        this.loading = false
        this.pageData = response
        this.pageData.data = this.pageData.data.map(item => {
          item.goods_exchange = item.goods_exchange === 1
          item.label_names_gift = item.label_names_gift && item.label_names_gift.replaceAll(',', '、')
          item.label_names_area = item.label_names_area && item.label_names_area.replaceAll(',', '、')
          return item
        })
        this.$nextTick(() => {
          this.$refs.tableWrap.$refs.table.doLayout()
        })
      })
    },

    GET_ComboRecordsList () {
      this.comboRecordsLoading = true
      API_Combo.getRecordList(this.comboBindingRecordsParams)
        .then(response => {
          if (response.data.length === 0 && response.page_no > 1) {
            this.comboBindingRecordsParams.page_no--
            return this.GET_ComboRecordsList()
          }

          this.comboRecordsLoading = false
          this.comboBindingTableData = response
        })
        .finally(() => {
          this.comboRecordsLoading = false
        })
    },

    /** 编辑礼包*/
    handleEditCombo (row) {
      this.$store.dispatch('addVisitedViews', this.$route)
      this.$router.push({
        name: 'comboEditComboCard',
        params: {
          id: row.id
        }
      })
    },

    /** 删除商品 */
    handleDeleteCombo (row) {
      this.$confirm('确定要删除该礼包吗？删除后无法还原该礼包。', '提示', {
        type: 'warning'
      }).then(() => {
        API_Combo.delCombo(row.id).then(() => {
          this.GET_ComboList()
          this.$message.success('删除礼包成功！')
        })
      })
    },

    handleSelectionChange (val) {
      this.multipleSelection = val;
      let ids = []
      val.forEach(item => {
        ids.push(item.id)
      })

      this.idsList = ids
    },
    handleSortChange ({ prop, order }) {
      if (order) {
        this.params.sort = `${prop}_${order.includes('asc') ? 'asc' : 'desc'}`
      } else {
        this.params.sort = ''
      }
      this.GET_ComboList()
    },
    /**更改礼包前端名称 */
    async changeWebName (row) {
      row.showWebNameInput = false
      row.web_gift_name = row.web_gift_name.trim()
      let { id: combo_id, web_gift_name: combo_name } = row
      if (combo_name == '') {
        combo_name = row.gift_name
      }
      try {
        const res = await API_Combo.editCombEditName({ combo_id, combo_name })
        row.web_gift_name = combo_name
        this.$message.success('修改成功')
      } catch (error) {
        this.$message.warning(error.message)
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.num {
  cursor: pointer;
  color: #2155d5;
  text-decoration: underline;
}

.num:hover {
  text-decoration: underline;
}

.exportPPTForm {
  .el-radio {
    margin-top: 7px
  }

  /deep/ .site-logo {
    .deleteImg {
      position: absolute;
      right: -6px;
      top: -5px;
    }

    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      // overflow: hidden;

      &:hover {
        border-color: #409eff;
      }
    }
  }

  .site-logo-img {
    width: 120px;
    height: 120px;
    display: block;
  }

  .logo-uploader-icon {
    font-size: 20px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
  }
}
</style>
