import * as $GoodsCategoryApi from "@/api/goodsCategory";
export class GoodsCategoryProxy {
  children: GoodsCategoryProxy[] = [];
  props: any;

  static map: any = {};

  constructor(
    public id = 0,
    public label = "",
    public value = "",
    public level = 0,
    public leaf = level === 3
  ) {
    if (this.id === 0) {
      this.load();
      this.props = {
        lazy: true,
        emitPath: false,
        checkStrictly: true,
        expandTrigger: "hover",
        lazyLoad: (node, resolve) => {
          if (!node.data) return resolve();
          node.data.load().then(resolve);
        },
      };
    }
    if (this.leaf) this.children = null!;
  }

  load() {
    if (this.leaf || this.children.length) {
      return Promise.resolve(this.children);
    }

    return $GoodsCategoryApi
      .getGiftsCategoryLevelList(this.id, null)
      .then((resp) => {
        if (!resp) return [];

        this.children = resp.map((item) => {
          const proxy = new GoodsCategoryProxy(
            item.id,
            item.name,
            item.category_path,
            this.level + 1
          );
          GoodsCategoryProxy.map[item.category_path] = proxy;
          return proxy;
        });

        return this.children;
      });
  }

  change(path) {
    GoodsCategoryProxy.map[path].load();
  }
}
export class AllGoodsCategoryProxy {
  children: AllGoodsCategoryProxy[] = [];
  props: any;

  static map: any = {};

  constructor(
    public id = 0,
    public label = "",
    public value = "",
    public level = 0,
    public leaf = level === 3
  ) {
    if (this.id === 0) {
      this.load();
      this.props = {
        lazy: true,
        emitPath: false,
        checkStrictly: true,
        expandTrigger: "hover",
        lazyLoad: (node, resolve) => {
          if (!node.data) return resolve();
          node.data.load().then(resolve);
        },
      };
    }
    if (this.leaf) this.children = null!;
  }

  load() {
    if (this.leaf || this.children.length) {
      return Promise.resolve(this.children);
    }

    return $GoodsCategoryApi
      .getAllGiftsCategoryLevelList(this.id, null)
      .then((resp) => {
        if (!resp) return [];

        this.children = resp.map((item) => {
          const proxy = new AllGoodsCategoryProxy(
            item.id,
            item.name,
            item.category_path,
            this.level + 1
          );
          AllGoodsCategoryProxy.map[item.category_path] = proxy;
          return proxy;
        });

        return this.children;
      });
  }

  change(path) {
    AllGoodsCategoryProxy.map[path].load();
  }
}
export const $giftsCategory = {
  create: () => new GoodsCategoryProxy(),
  init: () => new AllGoodsCategoryProxy(),
};
