import { render, staticRenderFns } from "./combo-list.vue?vue&type=template&id=b84f2a40&scoped=true&"
import script from "./combo-list.vue?vue&type=script&lang=js&"
export * from "./combo-list.vue?vue&type=script&lang=js&"
import style0 from "./combo-list.vue?vue&type=style&index=0&id=b84f2a40&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b84f2a40",
  null
  
)

export default component.exports